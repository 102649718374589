import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const BirthdateInput = () => {
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [speedClass, setSpeedClass] = useState('earth');

  const navigate = useNavigate();

  const handleBlur = () => {
    let classes = ['earth'];
    if (year) classes.push('slow-orbit');
    if (year && month) classes.push('slower-orbit');
    if (year && month && day) classes.push('slowest-orbit');
    setSpeedClass(classes.join(' '));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const birthdate = `${year}-${month}-${day}`;
    localStorage.setItem('birthdate', birthdate);
    window.location.href = '/auth/google'; // Redireciona para a autenticação do Google
  };

  return (
    <div>
      <div className="container">
        <div className="sun"></div>
        <div className={speedClass}>
          <div className="moon"></div>
        </div>
      </div>
      <h1>Insira sua Data de Nascimento</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Ano: </label>
          <input type="number" value={year} onChange={(e) => setYear(e.target.value)} onBlur={handleBlur} required />
        </div>
        <div>
          <label>Mês: </label>
          <input type="number" value={month} onChange={(e) => setMonth(e.target.value)} onBlur={handleBlur} required />
        </div>
        <div>
          <label>Dia: </label>
          <input type="number" value={day} onChange={(e) => setDay(e.target.value)} onBlur={handleBlur} required />
        </div>
        <button type="submit">Próximo</button>
      </form>
    </div>
  );
};

export default BirthdateInput;
