import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSpring, animated } from '@react-spring/web';
import ReactSpeedometer from "react-d3-speedometer";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [luckScore, setLuckScore] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userData = query.get('user');

    if (userData) {
      const user = JSON.parse(decodeURIComponent(userData));
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      setLoading(false);

      // Obter a sorte do usuário
      const birthdate = localStorage.getItem('birthdate');
      if (birthdate) {
        console.log(`Solicitando sorte para a data de nascimento: ${birthdate}`);
        axios.get(`/api/luck?data_nascimento=${birthdate}`)
          .then(response => {
            setLuckScore(response.data.luckScore);
            console.log(`Sorte calculada: ${response.data.luckScore}`);
          })
          .catch(error => {
            console.error('Erro ao calcular a sorte!', error);
          });
      } else {
        console.error('Data de nascimento não encontrada no localStorage.');
      }
    } else {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
        setLoading(false);
      }
    }
  }, [location.search]);

  const springProps = useSpring({ number: luckScore, from: { number: -26 }, reset: true });

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (!user) {
    return <p>Nenhum usuário encontrado.</p>;
  }

  return (
    <div>
      <h1>Perfil do Usuário</h1>
      <p>Nome: {user.nome}</p>
      <p>Email: {user.email}</p>
      <p>Data de Nascimento: {localStorage.getItem('birthdate')}</p>
      <p>Valor de Sorte: 
        {luckScore !== null ? (
          <animated.span>{springProps.number.to(n => n.toFixed(2))}</animated.span>
        ) : (
          'Calculando...'
        )}
      </p>
      {luckScore !== null && (
        <ReactSpeedometer
          maxValue={48}
          minValue={-26}
          value={luckScore}
          needleColor="red"
          startColor="green"
          endColor="blue"
          segments={10}
          currentValueText="Sorte: ${value}"
        />
      )}
    </div>
  );
};

export default UserProfile;
