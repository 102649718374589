import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SolarSystem.scss';

const Home = ({ user }) => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/birthdate'); // Redireciona para a página de inserção da data de nascimento
  };

  return (
    <div className="solar-syst">
      <div className="sun"></div>
      <div className="mercury"></div>
      <div className="venus"></div>
      <div className="earth"></div>
      <div className="mars"></div>
      <div className="jupiter"></div>
      <div className="saturn"></div>
      <div className="uranus"></div>
      <div className="neptune"></div>
      <div className="pluto"></div>
      <div className="asteroids-belt"></div>
      <div className="description">
        <h1>Bem-vindo ao StarLuck</h1>
        <hr />
        {user ? (
          <p>Olá, {user.nome}! Bem-vindo de volta!</p>
        ) : (
          <button onClick={handleStart}>Login com Google</button>
        )}
      </div>
    </div>
  );
};

export default Home;
