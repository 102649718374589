import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import ReactTooltip from 'react-tooltip';

// Importando as imagens
import homeIcon from '../assets/imagens/home_ico.png';
import privacyPolicyIcon from '../assets/imagens/privacy_policy_icon.png';
import termsOfServiceIcon from '../assets/imagens/terms_of_service_icon.png';
import userProfileIcon from '../assets/imagens/user_profile_icon.png';
import logoutIcon from '../assets/imagens/logout_icon.png'; // Adicione o ícone de logout

const Menu = ({ handleLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`menu ${menuOpen ? 'menu--open' : 'menu--closed'}`}>
      <div className="button" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`tools ${menuOpen ? 'tools--visible' : 'tools--hidden'}`}>
        
          <Link to="/">  
          <div class="tooltipo">             
            <img className="icon pen" width="40" height="40" src={homeIcon} alt="Home Icon" title='home'/>
            <span class="tooltipo-content">
              <img decoding="async" src="https://blog.marcusoliveiradev.com.br/wp-content/uploads/2023/05/tooltip-com-HTML-e-CSS.jpg" alt="Tooltip com texto e imagem"/>
            <span class="tooltipo-text">
                Informação adicional
              </span>
            </span> 
          </div> 
          </Link>

        
        <Link to="/privacy-policy">
          <img className="icon trash" width="32" height="32" src={privacyPolicyIcon} alt="Privacy Policy Icon"/>
        </Link>
        <Link to="/terms-of-service">
          <img className="icon hidden" width="32" height="32" src={termsOfServiceIcon} alt="Terms of Service Icon"/>
        </Link>
        <Link to="/profile">
          <img className="icon help" width="32" height="32" src={userProfileIcon} alt="Profile Icon"/>
        </Link>
        
        <a href="#" onClick={handleLogout}>
          <img className="icon logout" width="32" height="32" src={logoutIcon} alt="Logout Icon"/>
        </a>
      </div>
    </div>
  );
};

export default Menu;
