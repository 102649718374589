import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Política de Privacidade</h1>
      <p>Sua privacidade é importante para nós. Esta política de privacidade explica como coletamos, usamos e protegemos suas informações pessoais.</p>

      <h2>Informações que Coletamos</h2>
      <p>Coletamos informações como nome, e-mail e data de nascimento quando você se registra no nosso site. Também podemos coletar informações sobre como você usa nosso site, incluindo as páginas que você visita, os links em que clica e outras ações que você realiza em conexão com o site.</p>
      <p>Além disso, podemos coletar informações técnicas sobre o seu dispositivo, como endereço IP, tipo de navegador, sistema operacional e outros dados semelhantes. Essas informações nos ajudam a melhorar a funcionalidade do site e a oferecer uma experiência de usuário mais personalizada.</p>

      <h2>Como Usamos Suas Informações</h2>
      <p>Usamos suas informações para calcular seu valor de "Sorte" baseado na posição dos astros e para personalizar sua experiência no nosso site. As informações que coletamos também nos permitem fornecer suporte ao cliente, responder a perguntas e preocupações, e enviar atualizações importantes sobre nossos serviços.</p>
      <p>Além disso, podemos usar suas informações para conduzir análises de dados, identificar tendências de uso e determinar a eficácia de nossas campanhas promocionais. Isso nos ajuda a melhorar continuamente nossos serviços e a desenvolver novos recursos que atendam melhor às necessidades de nossos usuários.</p>

      <h2>Compartilhamento de Informações</h2>
      <p>Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para cumprir a lei ou proteger nossos direitos. Podemos divulgar suas informações a autoridades legais ou governamentais em resposta a uma solicitação válida ou para proteger nossos direitos, propriedade ou segurança e a de nossos usuários.</p>
      <p>Em situações onde formos adquiridos ou fundidos com outra empresa, suas informações podem ser transferidas como parte dos ativos da empresa. Nesses casos, notificaremos você antes que suas informações pessoais sejam transferidas e se tornem sujeitas a uma política de privacidade diferente.</p>

      <h2>Segurança</h2>
      <p>Tomamos medidas para proteger suas informações pessoais contra acesso não autorizado, uso ou divulgação. Implementamos várias tecnologias e procedimentos de segurança para ajudar a proteger suas informações de acessos não autorizados e uso indevido. Isso inclui o uso de criptografia, firewalls e controles de acesso rigorosos.</p>
      <p>Embora nos esforcemos para proteger suas informações pessoais, nenhuma transmissão de dados pela internet ou sistema de armazenamento é completamente seguro. Portanto, não podemos garantir a segurança absoluta das informações transmitidas para ou armazenadas em nosso site. Se você tiver dúvidas sobre a segurança de suas informações, entre em contato conosco.</p>

      <h2>Seus Direitos</h2>
      <p>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Entre em contato conosco para exercer esses direitos. Além disso, você pode ter o direito de restringir ou objetar ao processamento de suas informações pessoais e o direito à portabilidade dos dados.</p>
      <p>Para exercer qualquer um desses direitos, por favor, envie-nos uma solicitação detalhando o seu pedido. Responderemos a todas as solicitações em conformidade com as leis aplicáveis. Tenha em mente que certas informações podem ser retidas conforme exigido por lei ou para fins comerciais legítimos.</p>

      <h2>Contato</h2>
      <p>Se você tiver dúvidas ou preocupações sobre nossa política de privacidade, entre em contato conosco em: [seu email de contato]. Estamos comprometidos em resolver quaisquer questões ou preocupações que você possa ter sobre como usamos suas informações pessoais.</p>
      <p>Valorizamos sua opinião e estamos sempre buscando melhorar nossas práticas de privacidade. Sinta-se à vontade para nos contatar com qualquer sugestão ou feedback que possa nos ajudar a servir melhor você e proteger sua privacidade.</p>
    </div>
  );
};

export default PrivacyPolicy;
