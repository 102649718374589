import React from 'react';

const TermsOfService = () => {
  return (
    <div>
      <h1>Termos de Serviço</h1>
      <p>Bem-vindo ao StarLuck. Estes termos de serviço descrevem as regras e regulamentos para o uso do nosso site.</p>

      <h2>Aceitação dos Termos</h2>
      <p>Ao acessar e usar nosso site, você aceita e concorda em cumprir os termos e condições descritos aqui. Se você não concorda com qualquer parte destes termos, por favor, não utilize nosso site.</p>
      <p>Reservamo-nos o direito de modificar estes termos a qualquer momento, e tais modificações serão efetivas imediatamente após a publicação no site. É sua responsabilidade revisar os termos periodicamente para estar ciente de quaisquer alterações.</p>

      <h2>Uso do Site</h2>
      <p>Nosso site e seus conteúdos são fornecidos apenas para seu uso pessoal e não comercial. Você concorda em não usar o site para qualquer propósito ilegal ou proibido por estes termos.</p>
      <p>Você concorda em não usar o site de maneira que possa danificar, desativar, sobrecarregar ou prejudicar qualquer servidor, rede ou outros usuários. Isso inclui, mas não se limita a, tentar obter acesso não autorizado a qualquer parte do site, outras contas, sistemas de computador ou redes conectadas ao site.</p>

      <h2>Conteúdo Gerado pelo Usuário</h2>
      <p>Você pode ser capaz de enviar, postar ou exibir conteúdo no nosso site. Ao fazer isso, você concede ao StarLuck uma licença não exclusiva, livre de royalties, mundial e transferível para usar, reproduzir, modificar, adaptar, publicar, traduzir, criar trabalhos derivados, distribuir e exibir tal conteúdo em qualquer meio.</p>
      <p>Você é o único responsável por todo o conteúdo que postar no site. Você concorda em não postar conteúdo que seja ilegal, ofensivo, difamatório, invasivo da privacidade de outra pessoa, ou que infrinja os direitos de propriedade intelectual de qualquer pessoa.</p>

      <h2>Isenção de Garantias</h2>
      <p>O site é fornecido "como está" e "conforme disponível". Não garantimos que o site será ininterrupto, livre de erros ou seguro. Não fazemos representações ou garantias de qualquer tipo, expressas ou implícitas, sobre a operação do site ou as informações, conteúdos ou materiais incluídos no site.</p>
      <p>Na máxima extensão permitida pela lei aplicável, renunciamos a todas as garantias, expressas ou implícitas, incluindo, mas não se limitando a, garantias implícitas de comercialização e adequação a um propósito específico.</p>

      <h2>Limitação de Responsabilidade</h2>
      <p>Em nenhuma circunstância o StarLuck será responsável por quaisquer danos diretos, indiretos, incidentais, especiais ou consequentes que resultem do uso ou da incapacidade de usar o site, mesmo que tenhamos sido avisados da possibilidade de tais danos.</p>
      <p>Esta limitação de responsabilidade se aplica a todas as causas de ação, quer baseadas em contrato, garantia, delito ou qualquer outra teoria legal.</p>

      <h2>Indenização</h2>
      <p>Você concorda em indenizar, defender e isentar de responsabilidade o StarLuck, seus diretores, funcionários, agentes e terceiros, por quaisquer perdas, custos, responsabilidades e despesas (incluindo honorários advocatícios razoáveis) relacionados a ou decorrentes de seu uso ou incapacidade de usar o site, qualquer conteúdo que você poste, sua violação de quaisquer termos deste acordo ou sua violação de quaisquer direitos de terceiros.</p>
      <p>Esta obrigação de indenização sobreviverá à rescisão ou expiração destes termos e ao seu uso do site.</p>

      <h2>Legislação Aplicável</h2>
      <p>Estes termos serão regidos e interpretados de acordo com as leis do país em que estamos localizados, sem dar efeito a quaisquer princípios de conflitos de leis. Você concorda que qualquer ação legal ou processo judicial entre nós e você para qualquer propósito relacionado a estes termos será submetido exclusivamente a um tribunal de jurisdição competente localizado no país em que estamos localizados.</p>
      <p>Se qualquer disposição destes termos for considerada inválida ou inexequível por qualquer tribunal de jurisdição competente, as outras disposições destes termos permanecerão em pleno vigor e efeito.</p>

      <h2>Contato</h2>
      <p>Se você tiver dúvidas ou preocupações sobre nossos Termos de Serviço, entre em contato conosco em: [seu email de contato]. Estamos comprometidos em resolver quaisquer questões ou preocupações que você possa ter sobre como usamos suas informações pessoais.</p>
      <p>Valorizamos sua opinião e estamos sempre buscando melhorar nossos serviços. Sinta-se à vontade para nos contatar com qualquer sugestão ou feedback que possa nos ajudar a servir melhor você e proteger sua privacidade.</p>
    </div>
  );
};

export default TermsOfService;
