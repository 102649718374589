import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import UserProfile from './components/UserProfile';
import BirthdateInput from './components/BirthdateInput';
import axios from 'axios';
import Menu from './components/Menu'; // Importe o componente Menu

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userParam = params.get('user');
    if (userParam) {
      const user = JSON.parse(decodeURIComponent(userParam));
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      navigate('/profile');
    } else {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }
  }, [navigate]);

  const handleLogout = () => {
    axios.get('/logout')
      .then(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('birthdate'); // Limpar data de nascimento também
        setUser(null);
        window.location.href = '/';
      })
      .catch(error => {
        console.error('Erro ao fazer logout!', error);
      });
  };

  return (
    <div>
      <Menu handleLogout={handleLogout} /> {/* Passe a função handleLogout como uma prop */}
      <Routes>
        <Route path="/" element={<Home user={user} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/birthdate" element={<BirthdateInput />} />
      </Routes>
    </div>
  );
}

export default App;
